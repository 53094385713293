import { axios } from "./resolve"
import { AxiosResponse } from "axios"
import { DiscoverCursor, DiscoverResp, ErrorResponse } from "@types"
import {
  DiscoverFarcasterResp,
  ProfilesEntry,
  TrendingResp,
} from "@/types/farcaster"

const API = "/discover"

export async function getSubscriptionNotes(
  page: number,
  userId: string
): Promise<TrendingResp | ErrorResponse> {
  console.log("About to get Farcaster followers...")
  const response: AxiosResponse = await axios.get(
    `${API}/subscriptions/posts/${userId}?page=${page || 1}`,
    {}
  )

  if ("msg" in response.data) {
    console.error("Exception occurred", response.data)
    return response.data
  }

  return response.data
}

export async function getTrendingFarcasterNotes(
  page: number
): Promise<TrendingResp | ErrorResponse> {
  console.log("About to get Trending followers...")
  const response: AxiosResponse = await axios.get(
    `${API}/trending/farcaster?page=${page || 1}`,
    {}
  )

  console.log("Received trending note response...", response)

  if ("msg" in response.data) {
    console.error("Exception occurred", response.data)
    return response.data
  }

  return response.data
}

export async function getRecentNotes(
  cursor?: DiscoverCursor,
  tag?: string
): Promise<TrendingResp | ErrorResponse> {
  console.log("About to get recent notes...", cursor, tag)

  let url = `${API}/recent`
  const params = new URLSearchParams()

  if (cursor) {
    params.append("cursor", JSON.stringify(cursor))
  }
  if (tag) {
    params.append("tag", tag)
  }

  if (params.toString()) {
    url += `?${params.toString()}`
  }

  const response: AxiosResponse = await axios.get(url)

  console.log("Received recent note response...", response)

  if ("msg" in response.data) {
    console.error("Exception occurred", response.data)
    return response.data
  }

  return response.data
}

export async function getTrendingNotes(
  cursor?: DiscoverCursor,
  tag?: string
): Promise<TrendingResp | ErrorResponse> {
  console.log("About to get Trending notes...", cursor, tag)

  let url = `${API}/trending`
  const params = new URLSearchParams()

  if (cursor) {
    params.append("cursor", JSON.stringify(cursor))
  }
  if (tag) {
    params.append("tag", tag)
  }

  if (params.toString()) {
    url += `?${params.toString()}`
  }

  const response: AxiosResponse = await axios.get(url)

  console.log("Received trending note response...", response)

  if ("msg" in response.data) {
    console.error("Exception occurred", response.data)
    return response.data
  }

  return response.data
}

// If wallet_address is empty,
// get ALL farcaster posts.
export async function getFarcasterNotes(
  page: number,
  wallet_address?: string
): Promise<TrendingResp | ErrorResponse> {
  console.log("About to get Farcaster followers...")

  let url = `${API}/farcaster/posts`

  if (wallet_address) url += `/${wallet_address}`

  const response: AxiosResponse = await axios.get(
    `${url}?page=${page || 1}`,
    {}
  )

  if ("msg" in response.data) {
    console.error("Exception occurred", response.data)
    return response.data
  }

  return response.data
}

export async function getFarcasterAccount(
  wallet_address: string
): Promise<ProfilesEntry | false> {
  console.log("About to get Farcaster followers...")
  const response: AxiosResponse<ProfilesEntry | false> = await axios.get(
    `${API}/farcaster/${wallet_address}/fcAddress`
  )

  return response.data
}

export async function getFarcasterFollowers(
  wallet_address: string
): Promise<DiscoverFarcasterResp | ErrorResponse> {
  console.log("About to get Farcaster followers...")
  const response: AxiosResponse = await axios.get(
    `${API}/farcaster/${wallet_address}/followers`,
    {}
  )

  if ("msg" in response.data) {
    console.error("Exception occurred", response.data)
    return response.data
  }

  return response.data
}

export async function getNotes({
  days,
  selector,
  pageNum,
  category,
}: {
  days: number
  selector: string
  pageNum: number
  category?: string
}): Promise<DiscoverResp | undefined> {
  console.log("About to get discover notes via API...")
  const response: AxiosResponse = await axios.get(`${API}`, {
    params: {
      days,
      selector,
      page: pageNum,
      category,
    },
  })

  console.log("Got discover notes via API!")

  if (response.status === 200) {
    return response.data
  }
}
