import React from "react"

type GoogleAnalyticsProps = {
  google_analytics_id: string
}

export const GoogleAnalyticsHead: React.FC<GoogleAnalyticsProps> = ({
  google_analytics_id,
}) => {
  const idType = google_analytics_id.split("-")[0]

  const getHeadScript = () => {
    switch (idType) {
      case "GTM":
        return (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${google_analytics_id}');`,
              }}
            />
          </>
        )
      case "UA":
      case "G":
        return (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${google_analytics_id}`}
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${google_analytics_id}');`,
              }}
            />
          </>
        )
      default:
        return null
    }
  }

  return <>{getHeadScript()}</>
}

export const GoogleAnalyticsBody: React.FC<GoogleAnalyticsProps> = ({
  google_analytics_id,
}) => {
  const idType = google_analytics_id.split("-")[0]

  const getBodyScript = () => {
    if (idType === "GTM") {
      return (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${google_analytics_id}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      )
    }
    return null
  }

  return <>{getBodyScript()}</>
}
