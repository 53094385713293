import Head from "next/head"
import { ReactNode } from "react"
import { Disclosure } from "@headlessui/react"
import useColor from "hooks/useColor"
import Color from "color"
import BlogThemeNavbar from "components/public/BlogTheme/Navbar"
import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"

import Footer from "components/public/BlogTheme/Footer"

import styles from "styles/Public.module.css"
import { useScrollDirection } from "hooks/useScrollDirection"
import useResizeObserver from "use-resize-observer"
import useFont from "hooks/useFont"
import {
  GoogleAnalyticsBody,
  GoogleAnalyticsHead,
} from "components/GoogleAnalytics"
import usePageScrolled from "hooks/usePageScrolled"
import clsx from "clsx"

interface Props {
  className?: string
  children: ReactNode
  showProgressBar?: boolean
}

export default function PublicLayout({
  showProgressBar,
  className,
  children,
}: Props) {
  const { ref, height = 32 } = useResizeObserver<HTMLDivElement>()
  const scrolled = usePageScrolled()
  const direction = useScrollDirection()

  // This is a hack - typed as 'any' to avoid specifying
  // every single possible color combination.
  const color: any = useColor()

  const transparentNavbar = Color(color?.secondary || "#ffffff")
    .fade(0.2)
    .toString()

  const blog = useSelector(selectBlog)
  const { font, headerFont } = useFont(blog)

  return (
    <div
      id="para-document"
      className={clsx(
        className ? className : "",
        styles.public,
        "public relative min-h-screen justify-between flex flex-col"
      )}
      style={{
        // @ts-ignore
        "--color-secondary": color.secondary,
        "--color-primary": color.primary,
        "--color-primary-text": color.primaryText,
        // This will only be set if the user has a custom font.
        "--font-face": font?.fontName,
        "--header-font-face": headerFont?.fontName,
        "--text-custom-50": color.fontColor50,
        "--text-custom-100": color.fontColor100,
        "--text-custom-200": color.fontColor200,
        "--text-custom-300": color.fontColor300,
        "--text-custom-400": color.fontColor400,
        "--text-custom-500": color.fontColor500,
        "--text-custom-600": color.fontColor600,
        "--text-custom-700": color.fontColor700,
        "--text-custom-800": color.fontColor800,
        "--text-custom-900": color.fontColor900,

        "--bg-custom-50": color.bgColor50,
        "--bg-custom-100": color.bgColor100,
        "--bg-custom-200": color.bgColor200,
        "--bg-custom-300": color.bgColor300,
        "--bg-custom-400": color.bgColor400,
        "--bg-custom-500": color.bgColor500,
        "--bg-custom-600": color.bgColor600,
        "--bg-custom-700": color.bgColor700,
        "--bg-custom-800": color.bgColor800,
        "--bg-custom-900": color.bgColor900,

        "--text-primary-50": color.primaryColor50,
        "--text-primary-100": color.primaryColor100,
        "--text-primary-200": color.primaryColor200,
        "--text-primary-300": color.primaryColor300,
        "--text-primary-400": color.primaryColor400,
        "--text-primary-500": color.primaryColor500,
        "--text-primary-600": color.primaryColor600,
        "--text-primary-700": color.primaryColor700,
        "--text-primary-800": color.primaryColor800,
        "--text-primary-900": color.primaryColor900,
      }}
    >
      {blog.google_analytics_id && (
        <>
          <GoogleAnalyticsBody google_analytics_id={blog.google_analytics_id} />
          <Head key={blog.google_analytics_id}>
            <GoogleAnalyticsHead
              google_analytics_id={blog.google_analytics_id}
            />
          </Head>
        </>
      )}
      {font?.url && (
        <Head key={font.url}>
          <link href={font?.url} rel="stylesheet" />
        </Head>
      )}

      {blog?.custom_css && (
        <Head key={"custom-css"}>
          <style>{blog.custom_css}</style>
        </Head>
      )}

      {headerFont?.url && (
        <Head key={headerFont.url}>
          <link href={headerFont?.url} rel="stylesheet" />
        </Head>
      )}

      <Disclosure
        as="nav"
        style={{ top: direction === "down" ? 4 - height : 0 }}
        className={clsx(
          scrolled ? "shadow" : "",
          "sticky z-50 transition-all duration-300 block"
        )}
      >
        {({ open }) => (
          <div
            className={clsx(
              scrolled ? "" : "border-b border-custom-200 pb-2",

              "backdrop-filter backdrop-blur "
            )}
            style={{ backgroundColor: transparentNavbar }}
            ref={ref}
          >
            {blog && (
              <BlogThemeNavbar showProgressBar={showProgressBar} blog={blog} />
            )}
          </div>
        )}
      </Disclosure>

      {children}

      <Footer />
    </div>
  )
}
