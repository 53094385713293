import { useEffect, useState } from "react"

export default function usePageScrolled() {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    function updateStyles() {
      if (window.scrollY > 75) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    updateStyles()
    window.addEventListener("scroll", updateStyles, { passive: true })
    window.addEventListener("resize", updateStyles)

    return () => {
      // @ts-ignore
      window.removeEventListener("scroll", updateStyles, { passive: true })
      window.removeEventListener("resize", updateStyles)
    }
  }, [])

  return scrolled
}
