import SubscribeButton from "components/SubscribeButton"
import Logo from "components/Logo"
import { Blog } from "@types"
import Link from "next/link"
import useBlogUrl from "hooks/useBlogUrl"
import clsx from "clsx"
import useColor from "hooks/useColor"

import ProgressBar from "util/progressBar"
import { useRouter } from "next/router"
import useUser from "hooks/useUser"
import AvatarDropdown from "components/navbar/AvatarDropdown"

interface Props {
  blog: Blog
  showProgressBar?: boolean
}

export default function BlogThemeNavbar(props: Props) {
  const blogUrl = useBlogUrl({ blog: props.blog })
  const user = useUser()
  const color = useColor()

  const isParagraphUrl = (url: string) => url.includes("https://paragraph.xyz")
  const router = useRouter()

  // Only show links on the blog route (not post page).
  const showLinks = router.pathname === "/[blogname]"

  return (
    <div className="relative">
      {props.showProgressBar && (
        <ProgressBar bgcolor={color.primary} duration={0.1} />
      )}

      <div className="mx-auto">
        <div className="flex justify-between h-14 sm:px-6 lg:px-8 px-4 gap-x-4 py-1">
          <div className="flex sm:basis-40 lg:basis-52">
            <div className="flex items-center">
              {blogUrl.url && props.blog.logo_url && (
                <Link href={blogUrl.url} className="cursor-pointer">
                  <Logo
                    customUrl={props.blog.logo_url}
                    className="flex content-center h-10 w-10 sm:h-auto sm:w-auto"
                  />
                </Link>
              )}
            </div>
          </div>

          <h1
            className="text-custom-900 py-2 text-center text-md sm:text-2xl font-bold items-center transition-all duration-300 flex sm:grow sm:justify-center"
            id="publication-title"
          >
            {blogUrl.url && (
              <Link href={blogUrl.url} className="line-clamp-2">
                {props.blog.name}
              </Link>
            )}
          </h1>
          <div className="flex items-center justify-end basis-0 grow space-x-4 sm:basis-40 lg:basis-52 sm:grow-0">
            <SubscribeButton className="text-xs sm:text-sm" />
            {user.loggedIn && <AvatarDropdown showExtraLinks />}
          </div>
        </div>
        {showLinks && props.blog.links && props.blog.links.length > 0 && (
          <div className="border-t mt-2 pt-2 border-custom-200 flex overflow-x-auto sm:justify-center whitespace-nowrap">
            {props.blog.links?.map((link, idx) => (
              <span key={idx} className="m-auto sm:m-0">
                <Link
                  href={link.url}
                  target={isParagraphUrl(link.url) ? "_self" : "_blank"}
                  className={clsx(
                    "rounded-md py-2 px-3 text-custom-500 inline-flex items-center text-sm font-medium color-hover transition-all hover:bg-text-custom-200 "
                  )}
                  aria-current={false ? "page" : undefined}
                >
                  {link.friendly_name}
                </Link>
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
