import type { Blog } from "@types"

export type FONT_ENTRY = {
  url: string | undefined
  displayName: string
  fontName: string | undefined
}

export const FONT_MAPPING: Array<FONT_ENTRY> = [
  {
    displayName: "Default",
    url: undefined,
    fontName: undefined,
  },
  {
    displayName: "Sans Serif Modern",
    url: "https://fonts.googleapis.com/css2?family=Montserrat&display=swap",
    fontName: "Montserrat",
  },
  {
    displayName: "Sans Serif Clear",
    url: "https://fonts.googleapis.com/css2?family=Poppins&display=swap",
    fontName: "Poppins",
  },
  {
    displayName: "Sans Serif Condensed",
    url: "https://fonts.googleapis.com/css2?family=Oxygen&display=swap",
    fontName: "Oxygen",
  },
  {
    displayName: "Mono",
    url: "https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap",
    fontName: "Roboto Mono",
  },

  {
    displayName: "Serif",
    url: "https://fonts.googleapis.com/css2?family=Spectral&display=swap",
    fontName: "Spectral",
  },
  {
    displayName: "Serif Charter",
    url: "/fonts/charter.css",
    fontName: "charter",
  },
  {
    displayName: "Serif News",
    url: "https://fonts.googleapis.com/css2?family=Newsreader&display=swap",
    fontName: "Newsreader",
  },
  {
    displayName: "Serif Times New Roman",
    url: "https://fonts.googleapis.com/css2?family=Tinos&display=swap",
    fontName: "Tinos",
  },
  {
    displayName: "Serif Bold",
    url: "https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap",
    fontName: "Playfair Display",
  },
  {
    displayName: "Serif Montagu",
    url: "https://fonts.googleapis.com/css2?family=Montagu+Slab&display=swap",
    fontName: "Montagu Slab",
  },
  {
    displayName: "Serif Playful",
    url: "https://fonts.googleapis.com/css2?family=Forum&display=swap",
    fontName: "Forum",
  },
  {
    displayName: "Handwritten Condensed",
    url: "https://fonts.googleapis.com/css2?family=Caveat&display=swap",
    fontName: "Caveat",
  },
  {
    displayName: "Handwritten Loud",
    url: "https://fonts.googleapis.com/css2?family=Itim&display=swap",
    fontName: "Itim",
  },
  {
    displayName: "Handwritten Clean",
    url: "https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap",
    fontName: "Patrick Hand",
  },
  {
    displayName: "Cursive",
    url: "https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap",
    fontName: "Dancing Script",
  },
  {
    displayName: "Noto Serif Lao",
    url: "https://fonts.googleapis.com/css2?family=Noto+Serif+Lao&display=swap",
    fontName: "Noto Serif Lao",
  },
  {
    displayName: "Noto Sans Thai",
    url: "https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap",
    fontName: "Noto Sans Thai",
  },
  {
    displayName: "Ubuntu",
    url: "https://fonts.googleapis.com/css2?family=Ubuntu&display=swap",
    fontName: "Ubuntu",
  },
]

export default function useFont(blog: Blog) {
  const font = blog.font
    ? FONT_MAPPING.find((f) => f.displayName === blog.font)
    : null

  const headerFont = blog.header_font
    ? FONT_MAPPING.find((f) => f.displayName === blog.header_font)
    : null

  return { font, headerFont }
}
